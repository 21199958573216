/* Classes */

class lazyLoad {
    constructor(element, type, method, message) {
        this.element = element;
        this.type = type;
        this.method = method;
        this.message = message;
    }
};

/* / Classes */

/* Variables */

const verbose = false;

const mimes = {
    jpgxr: "image/vnd.ms-photo",
    jpg2000: "image/jp2",
    webp: "image/webp",
}

const win = $(window);
win.width = win.outerWidth(true);
win.height = win.outerHeight(true);
win.topPosition = win.scrollTop();
win.bottomPosition = win.topPosition + win.height;
win.leftPosition = win.scrollLeft();
win.rightPosition = win.leftPosition + win.width;
win.yCenter = (win.bottomPosition) / 2;
win.xCenter = win.width / 2;
win.center = {
    "x": win.xCenter,
    "y": win.yCenter
};
const doc = $(document);
let loadedElements = [];
let imageElements = $('[data-type="image"]');
let pictureElements = $('[data-type="picture"]');
let videoElements = $('[data-type="video"]');
let backgroundImageElements = $('[data-type="backgroundImage"]');
let onViewportLazyLoad = $('[data-method="viewport"]');
let onClickLazyLoad = $('[data-method="click"]');

if (verbose) {
    console.log('Verbose Mode On');
    console.log("Image Elements :");
    console.log(imageElements);
    console.log("Picture Elements :");
    console.log(pictureElements);
    console.log("Video Elements :");
    console.log(videoElements);
    console.log("Background Image Elements :");
    console.log(backgroundImageElements);
    console.log("On Viewport Lazy Load Elements");
    console.log(onViewportLazyLoad);
    console.log("On Click lazy load Elements");
    console.log(onClickLazyLoad);
}
/* / Variables */

/* Functions */

const processLoadedImage = function(jQueryElement) {
    jQueryElement.method = jQueryElement.attr('data-method');
    jQueryElement.removeAttr('data-type');
    jQueryElement.removeAttr('data-method');
    jQueryElement.removeAttr('data-src');
    jQueryElement.removeAttr('data-final');
    jQueryElement.removeAttr('data-webp');
    jQueryElement.removeAttr('data-jpg2000');
    jQueryElement.removeAttr('data-jpgxr');
    jQueryElement.attr('data-loaded', 'loaded');
    let temporalLog = new lazyLoad(jQueryElement, "image", jQueryElement.method, `Image Loaded Successfully Trough ${jQueryElement.method}`);
    loadedElements.push(temporalLog);
    updateElements();
}

const processLoadedPicture = function(jQueryElement) {
    jQueryElement.method = jQueryElement.attr('data-method');
    jQueryElement.removeAttr('data-type');
    jQueryElement.removeAttr('data-method');
    jQueryElement.attr('data-loaded', 'loaded');
    let sources = jQueryElement.find('source');
    sources.each(function() {
        let $this = $(this);
        $this.removeAttr('data-srcset');
    });
    let image = jQueryElement.find('image');
    image.removeAttr('data-src');
    image.removeAttr('data-final');
    image.removeAttr('data-webp');
    image.removeAttr('data-jpg2000');
    image.removeAttr('data-jpgxr');
    let temporalLog = new lazyLoad(jQueryElement, "Picture", jQueryElement.method, `Picture Loaded Successfully Trough ${jQueryElement.method}`);
    loadedElements.push(temporalLog);
}

const processLoadedBackgroundImage = function(jQueryElement) {
    jQueryElement.method = jQueryElement.attr('data-method');
    jQueryElement.removeAttr('data-type');
    jQueryElement.removeAttr('data-method');
    jQueryElement.attr('data-loaded', 'loaded');
    jQueryElement.removeAttr('data-src');
    jQueryElement.removeAttr('data-final');
    jQueryElement.removeAttr('data-webp');
    jQueryElement.removeAttr('data-jpg2000');
    jQueryElement.removeAttr('data-jpgxr');
    let temporalLog = new lazyLoad(jQueryElement, "Background Image", jQueryElement.method, `Background Image Loaded Successfully Trough ${jQueryElement.method}`);
    loadedElements.push(temporalLog);
}

const elementEngineInit = function() {
    if (verbose) {
        console.log("Source Detector Report: --- Source Detector Triggered");
        console.log("Source Detector Report: --- Detecting Prefferred Image Source To Display");
        console.log(`
            Source Detector Report:
            Priority order: 
                1. - Webp.
                2. - jpeg200.
                3. - jpegXr.
                4. - Original.
        `);
    }
    Modernizr.on('jpegxr', function(result) {
        addEvents("Check Number One JPGXR");
        if (result) {
            // supported

            if (verbose) {
                console.log("Source Detector Report Browser Accepts jpgeg Xr Image Format");
            }

            imageElements.each(function() {
                let $this = $(this);
                let regular = $this.attr('data-src');
                let jpgxr = $this.attr('data-jpgxr');
                let final = "";
                if (jpgxr != undefined) {
                    if (verbose) {
                        console.log("Image Source Detector Report Image Tags Has A JPEG XR Url");
                        console.log(jpgxr);
                    }
                    final = jpgxr;
                } else {
                    console.warn('Image Source Detector Report Image Does not Holds a JPEG XR Url [data-jpgxr], Fallback to Regular [data-src]');
                    final = regular;
                }
                $(this).attr('data-final', final);
            });

            pictureElements.each(function() {

                let $thisPicture = $(this);
                let sources = $(this).find('source');
                let image = $(this).find('img');

                sources.each(function() {

                    let $thisSource = $(this);

                    $thisSource.srcset = $thisSource.attr('data-srcset');
                    if ($thisSource.srcset == undefined) {
                        console.error('Picture Source Detector Report --- Source Tag is missing required field [data-srcset]');
                        console.error($thisSource);
                    }

                    $thisSource.type = $thisSource.attr('type');
                    if ($thisSource.type == undefined) {
                        console.error('Picture Source Detector Report --- Source Tag Is Missing Required Field [type]');
                        console.error($thisSource);
                    }
                });
                image.each(function() {
                    let $this = $(this);
                    let regular = $this.attr('data-src');
                    let jpgxr = $this.attr('data-jpgxr');
                    let final = "";
                    if (jpgxr != undefined) {
                        if (verbose) {
                            console.log("Image Source Detector Report Image Tags Has A jpgxr Url");
                            console.log(jpgxr);
                        }
                        final = jpgxr;
                    } else {
                        console.warn('Image Source Detector Report Image Does not Holds a jpgxr Url [data-jpgxr], Fallback to Regular [data-src]');
                        final = regular;
                    }
                    $(this).attr('data-final', final);
                });
            });

            backgroundImageElements.each(function() {
                let $this = $(this);
                let regular = $this.attr('data-src');
                let jpgxr = $this.attr('data-jpgxr');
                let final = "";
                if (jpgxr != undefined) {
                    if (verbose) {
                        console.log("Background Image Source Detector Report Background Image Tags Has A JPEG XR Url");
                        console.log(jpgxr);
                    }
                    final = jpgxr;
                } else {
                    console.warn('Background Image Source Detector Report Background Image Does not Holds a JPEG XR Url [data-jpgxr], Fallback to Regular [data-src]');
                    final = regular;
                }
                $(this).attr('data-final', final);
            });

            videoElements.each(function(){
                let $this = $(this);
                let regular = $this.attr('data-poster');
                let jpgxr = $this.attr('data-posterjpgxr');
                let final = "";
                if(jpgxr != undefined){
                    if(verbose){
                        console.log("Video Tag Poster Source Detector Report: --- Video Tag has a JPEG XR Url Poster");
                        console.log(jpgxr);
                    }
                    final = jpgxr;
                } else {
                    console.warn('video Tag Poster Source Detector Report: --- Video Tag Does Not  Holds a JPEG XR Url [data-posterjpgxr], fall back to regular [data-poster]');
                    final = regular;
                }
                $this.attr('data-posterfinal',final);
            });

        }
    });
    Modernizr.on('jpeg2000', function(result) {
        addEvents("Check Number Two JPG2000");
        if (result) {
            // supported
            if (verbose) {
                console.log("Source Detector Report Browser Accepts jpgeg Xr Image Format");
            }

            imageElements.each(function() {
                let $this = $(this);
                let regular = $this.attr('data-src');
                let jpg2000 = $this.attr('data-jpg2000');
                let final = "";
                if (jpg2000 != undefined) {
                    if (verbose) {
                        console.log("Image Source Detector Report Image Tags Has A JPEG 2000 Url");
                        console.log(jpg2000);
                    }
                    final = jpg2000;
                } else {
                    console.warn('Image Source Detector Report Image Does not Holds a JPEG 2000 Url [data-jpg2000], Fallback to Regular [data-src]');
                    final = regular;
                }
                $(this).attr('data-final', final);
            });

            pictureElements.each(function() {

                let $thisPicture = $(this);
                let sources = $(this).find('source');
                let image = $(this).find('img');

                sources.each(function() {

                    let $thisSource = $(this);

                    $thisSource.srcset = $thisSource.attr('data-srcset');
                    if ($thisSource.srcset == undefined) {
                        console.error('Picture Source Detector Report --- Source Tag is missing required field [data-srcset]');
                        console.error($thisSource);
                    }

                    $thisSource.type = $thisSource.attr('type');
                    if ($thisSource.type == undefined) {
                        console.error('Picture Source Detector Report --- Source Tag Is Missing Required Field [type]');
                        console.error($thisSource);
                    }
                });
                image.each(function() {
                    let $this = $(this);
                    let regular = $this.attr('data-src');
                    let jpg2000 = $this.attr('data-jpg2000');
                    let final = "";
                    if (jpg2000 != undefined) {
                        if (verbose) {
                            console.log("Image Source Detector Report Image Tags Has A jpg2000 Url");
                            console.log(jpg2000);
                        }
                        final = jpg2000;
                    } else {
                        console.warn('Image Source Detector Report Image Does not Holds a jpg2000 Url [data-jpg2000], Fallback to Regular [data-src]');
                        final = regular;
                    }
                    $(this).attr('data-final', final);
                });
            });

            backgroundImageElements.each(function() {
                let $this = $(this);
                let regular = $this.attr('data-src');
                let jpg2000 = $this.attr('data-jpg2000');
                let final = "";
                if (jpg2000 != undefined) {
                    if (verbose) {
                        console.log("Background Image Source Detector Report Background Image Tags Has A JPEG 2000 Url");
                        console.log(jpg2000);
                    }
                    final = jpg2000;
                } else {
                    console.warn('Background Image Source Detector Report Background Image Does not Holds a JPEG 2000 Url [data-jpg2000], Fallback to Regular [data-src]');
                    final = regular;
                }
                $(this).attr('data-final', final);
            });

            videoElements.each(function(){
                let $this = $(this);
                let regular = $this.attr('data-poster');
                let jpg2000 = $this.attr('data-posterjpg2000');
                let final = "";
                if(jpg2000 != undefined){
                    if(verbose){
                        console.log("Video Tag Poster Source Detector Report: --- Video Tag has a JPG 2000 Url Poster");
                        console.log(jpg2000);
                    }
                    final = jpg2000;
                } else {
                    console.warn('video Tag Poster Source Detector Report: --- Video Tag Does Not  Holds a JPG 2000 Url [data-posterjpg2000], fall back to regular [data-poster]');
                    final = regular;
                }
                $this.attr('data-posterfinal',final);
            });

        }
    });
    Modernizr.on('webp', function(result) {
        addEvents("Check Number Three WEBP");
        if (result) {
            // supported
            if (verbose) {
                console.log("Source Detector Report Browser Accepts Webp Image Format");
            }

            imageElements.each(function() {
                let $this = $(this);
                let regular = $this.attr('data-src');
                let webp = $this.attr('data-webp');
                let final = "";
                if (webp != undefined) {
                    if (verbose) {
                        console.log("Image Source Detector Report Image Tags Has A Webp Url");
                        console.log(webp);
                    }
                    final = webp;
                } else {
                    console.warn('Image Source Detector Report Image Does not Holds a Webp Url [data-webp], Fallback to Regular [data-src]');
                    final = regular;
                }
                $(this).attr('data-final', final);
            });

            pictureElements.each(function() {

                let $thisPicture = $(this);
                let sources = $(this).find('source');
                let image = $(this).find('img');

                sources.each(function() {

                    let $thisSource = $(this);

                    $thisSource.srcset = $thisSource.attr('data-srcset');
                    if ($thisSource.srcset == undefined) {
                        console.error('Picture Source Detector Report --- Source Tag is missing required field [data-srcset]');
                        console.error($thisSource);
                    }

                    $thisSource.type = $thisSource.attr('type');
                    if ($thisSource.type == undefined) {
                        console.error('Picture Source Detector Report --- Source Tag Is Missing Required Field [type]');
                        console.error($thisSource);
                    }

                });
                image.each(function() {
                    let $this = $(this);
                    let regular = $this.attr('data-src');
                    let webp = $this.attr('data-webp');
                    let final = "";
                    if (webp != undefined) {
                        if (verbose) {
                            console.log("Image Source Detector Report Image Tags Has A Webp Url");
                            console.log(webp);
                        }
                        final = webp;
                    } else {
                        console.warn('Image Source Detector Report Image Does not Holds a Webp Url [data-webp], Fallback to Regular [data-src]');
                        final = regular;
                    }
                    $(this).attr('data-final', final);
                });
            });

            backgroundImageElements.each(function() {
                let $this = $(this);
                let regular = $this.attr('data-src');
                let webp = $this.attr('data-webp');
                let final = "";
                if (webp != undefined) {
                    if (verbose) {
                        console.log("Background Image Source Detector Report Background Image Tags Has A Webp Url");
                        console.log(webp);
                    }
                    final = webp;
                } else {
                    console.warn('Background Image Source Detector Report Background Image Does not Holds a Webp Url [data-webp], Fallback to Regular [data-src]');
                    final = regular;
                }
                $(this).attr('data-final', final);

            });

            videoElements.each(function(){
                let $this = $(this);
                let regular = $this.attr('data-poster');
                let webp = $this.attr('data-posterwebp');
                let final = "";
                if(webp != undefined){
                    if(verbose){
                        console.log("Video Tag Poster Source Detector Report: --- Video Tag has a WEBP Url Poster");
                        console.log(webp);
                    }
                    final = webp;
                } else {
                    console.warn('video Tag Poster Source Detector Report: --- Video Tag Does Not  Holds a WEBP Url [data-posterwebp], fall back to regular [data-poster]');
                    final = regular;
                }
                $this.attr('data-posterfinal',final);
            });

        }
    });
    if (verbose) {
        imageElements.each(function() {
            let $this = $(this);
            let regular = $this.attr('data-src');
            if (regular == undefined) {
                console.error('Image Source Detector Report This Image Does not have a fall back or regular format URL', $this);
            }
            let webp = $this.attr('data-webp');
            let jpg2000 = $this.attr('data-jpg2000');
            let jpgxr = $this.attr('data-jpgxr');
            let tempObj = {
                "regular": regular,
                "webp": webp,
                "jpg2000": jpg2000,
                "jpgxr": jpgxr,
            }
            console.table(tempObj);
        });
    }
}
elementEngineInit();

const updateElements = function() {
    imageElements = $('[data-type="image"]');
    onViewpoerLazyLoad = $('[data-method="viewport"]');
}

const updateWindowInfo = function() {
    win.width = win.outerWidth(true);
    win.height = win.outerHeight(true);
    win.topPosition = win.scrollTop();
    win.bottomPosition = win.topPosition + win.height;
    win.leftPosition = win.scrollLeft();
    win.rightPosition = win.leftPosition + win.width;
    win.yCenter = (win.topPosition + win.bottomPosition) / 2;
    win.xCenter = win.width / 2;
    win.center = {
        "x": win.xCenter,
        "y": win.yCenter
    };
}

const isOnViewport = function(jQueryElement) {
    updateWindowInfo();
    jQueryElement.width = jQueryElement.outerWidth(true);
    jQueryElement.height = jQueryElement.outerHeight(true);
    jQueryElement.topPosition = jQueryElement.offset().top;
    jQueryElement.bottomPosition = jQueryElement.topPosition + jQueryElement.height;
    jQueryElement.leftPosition = jQueryElement.offset().left;
    jQueryElement.rightPosition = jQueryElement.leftPosition + jQueryElement.width;
    if (
        (jQueryElement.topPosition <= win.bottomPosition) &&
        (jQueryElement.bottomPosition >= win.topPosition) &&
        (jQueryElement.leftPosition <= win.rightPosition) &&
        (jQueryElement.rightPosition >= win.leftPosition)
    ) {
        return true;
    } else {
        return false;
    }
}

const loadImageTag = function(jQueryElement) {
    if (verbose) {
        console.log("Image Tag Loader Report: --- Image Tag Loader Activated");
        console.log("Image Tag Loader Report: --- Image Received");
        console.log(jQueryElement);
    }
    let realSrc = jQueryElement.attr('data-final');
    jQueryElement.attr('src', realSrc);
    if (verbose) {
        console.log("Image Tag Loader Report: --- Image Loaded With [data-final] Image Attribute By Image Source Detector");
    }
    processLoadedImage(jQueryElement);
    if (verbose) {
        console.log("Image Sent To Loaded Image Processor");
    }
}

const loadSourceTag = function(jQueryElement) {
    if (verbose) {
        console.log("Source Tag Loader Report: ---- Source Tag Loader Activated");
        console.log("Source Tag Loader Report: ---- Source Received");
        console.log(jQueryElement);
    }
    let media = jQueryElement.attr('media');
    let sourceTagSuccess = false;
    let srcset = jQueryElement.attr("data-srcset");
    if (srcset != undefined) {
        jQueryElement.attr('srcset', srcset);
        if (verbose) {
            console.log("Source Tag Loader Report: --- Source Tag Loaded");
            console.log(jQueryElement);
        }
        sourceTagSuccess = true;
    } else {
        console.error('Source Tag Loader Report: ---- This Source Tag Does Not contain  neccesary attribute [data-srcset] ');
    }
    return sourceTagSuccess;
}

const loadPictureTag = function(jQueryElement) {

    if (verbose) {
        console.log("Picture Tag Loader Report: ---- Picture Tag Loaded Activated");
        console.log("Picture Tag Loader Report: ---- Picture Received");
        console.log(jQueryElement);
    }

    let picture = jQueryElement;
    let sources = picture.find('source');
    let image = picture.find('img');

    sources.each(function() {
        let $this = $(this);
        if (verbose) {
            console.log("Picture Tag Loader Report: ---- Reading Source Tag Sub Rutine Activated    ");
            console.log("Picture Tag Loader Report: ---- Source Tag Received - Sending  Source Tag To source Tag Loader   ");
            console.log($this);
        }
        loadSourceTag($this);
    });

    loadImageTag(image);
    processLoadedPicture(jQueryElement);
}

const loadBackgroundImage = function(jQueryElement) {

    if (verbose) {
        console.log('Background Image Loader Report: --- Background Image Loader Activated');
        console.log('Background Image Loader Report: --- Background Image Element Received');
        console.log(jQueryElement);
    }

    let backgroundImageElement = jQueryElement;
    let backgroundToUse = backgroundImageElement.attr('data-final');
    backgroundImageElement.css('background-image', `url('${backgroundToUse}')`);

    processLoadedBackgroundImage(jQueryElement);

}

const loadVideoTag = function(jQueryElement){

    if (verbose){
        console.log('Video Tag Loader Report: --- Video Tag Loader Activated');
        console.log('Video Tag Loader Report: --- Video Tag Element Received');
        console.log(jQueryElement);        
    }
    
    let realPoster = jQueryElement.attr('data-posterfinal');
    jQueryElement.attr('poster',realPoster);

    let sources = jQueryElement.find('source');
    sources.each(function(){
        let realUrl = $(this).attr('data-src');
        $(this).attr('src',realUrl);
    });

    jQueryElement[0].load();

}

const loadBackgroundVideo = function(jQueryElement){

    if (verbose){
        console.log('Background Video Loader Report: --- Video Tag Loader Activated');
        console.log('Background Video Loader Report: --- Background Video Element Received');
        console.log(jQueryElement); 
    }
    let videoAttributes = `muted autoplay playsinline webkit-playsinline loop`;
    let videoStyles = `style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:-1;object-fit:cover;object-position:center center;"`;
    let elementPosition = jQueryElement.css('position');
    if(elementPosition == "static" || elementPosition == "initial"){
        jQueryElement.css('position','relative');
    }
    let sources = {
        mp4 : "",
        ogg : "",
        webm : "",
    }

    let mp4 = jQueryElement.attr('data-src');
    if (mp4 == undefined){
        console.error('Background Video Loader Report: ---- Error Required Attribute Does not exist in this element [data-src]');
        console.error(jQueryElement);
    } else {
        let sourceTag = `<source src=${mp4} type="video/mp4" />`;
        sources.mp4 = sourceTag;
    }

    let ogg =  jQueryElement.attr('data-srcogg');
    if(ogg == undefined){
        console.warn('Background Video Loader Report: ---- This Element Does not Hold OGG Version for BG Video');
        console.warn(jQueryElement);
    } else {
        let sourceTag = `<source src=${ogg} type="video/ogg" />`;
        sources.ogg = sourceTag;
    }
    
    let webm = jQueryElement.attr('data-srcwebm');
    if(webm == undefined){
        console.warn('Background Video Loader Report: ---- This Element Does not Hold Webm Version for BG Video');
        console.warn(jQueryElement);
    } else {
        let sourceTag = `<source src=${webm} type="video/webm" />`;
        sources.webm = sourceTag;        
    }

    let videoTag = 
    `
        <video ${videoAttributes} ${videoStyles}>
            ${sources.webm}
            ${sources.ogg}
            ${sources.mp4}
            <p>Your Browser Does not Support HTML5 Video</p>
        </video>
    `;
    
    let elementChildrens = jQueryElement.find('*');
    elementChildrens.each(function(){
        let $this = $(this);
        let zindex = $this.css('z-index');
        if (zindex == "auto" || zindex == "initial"){
            $this.css('z-index','1');
        }
    });
    jQueryElement.append(videoTag);
}

const loadIframeTag = function(jQueryElement){

    if (verbose) {
        console.log("Iframe Tag Loader Report: ---- Iframe Tag Loader Activated");
        console.log("Iframe Tag Loader Report: ---- Iframe Received");
        console.log(jQueryElement);
    }

    let realUrl = jQueryElement.attr('data-src');
    if(realUrl == undefined){
        console.error('Iframe Tag Loader Report: ---- Required Attribute [data-src] is missing in this iframe');
        console.error(jQueryElement);
    } else {
        jQueryElement.attr('src',realUrl);
    }

}

const loadingCop = function(jQueryElement) {

    jQueryElement.type = jQueryElement.attr('data-type');

    if (verbose) {
        console.log("Trafic Cop Report: --- Detecting Element Type");
    }

    if (jQueryElement.type == "image") {
        (verbose) ? console.log("Traffic Cop Report: Element Type = Image"): null;
        (verbose) ? console.log("Traffic Cop Report: Image Element Sent To Image Load"): null;
        (verbose) ? console.log(jQueryElement): null;
        loadImageTag(jQueryElement);
    } else

    if (jQueryElement.type == "picture") {
        (verbose) ? console.log("Traffic Cop Report: Element Type = picture"): null;
        (verbose) ? console.log("Traffic Cop Report: Picture Element Sent To Picture Load"): null;
        (verbose) ? console.log(jQueryElement): null;
        loadPictureTag(jQueryElement);
    } else

    if (jQueryElement.type == "backgroundImage") {
        (verbose) ? console.log("Traffic Cop Report: Element Type = Background Image"): null;
        (verbose) ? console.log("Traffic Cop Report: Background Image Element Sent To Background Load"): null;
        (verbose) ? console.log(jQueryElement): null;
        loadBackgroundImage(jQueryElement);
    } else

    if (jQueryElement.type == "video") {
        (verbose) ? console.log("Traffic Cop Report: Element Type = Video Element"): null;
        (verbose) ? console.log("Traffic Cop Report: Video Element Sent To Video Load"): null;
        (verbose) ? console.log(jQueryElement): null;
        loadVideoTag(jQueryElement);

    } else 
    
    if (jQueryElement.type == "backgroundVideo"){
        (verbose) ? console.log("Traffic Cop Report: Element Type = Background Video Element"): null;
        (verbose) ? console.log("Traffic Cop Report: Video Element Sent To Background Video Load"): null;
        (verbose) ? console.log(jQueryElement): null;
        loadBackgroundVideo(jQueryElement);
    } else

    if(jQueryElement.type == "iframe"){
        (verbose) ? console.log("Traffic Cop Report: Element Type = Background Iframe Element"): null;
        (verbose) ? console.log("Traffic Cop Report: Video Element Sent To Iframe Load"): null;
        (verbose) ? console.log(jQueryElement): null;
        loadIframeTag(jQueryElement);
    }
        
    else {
        console.error("This Element Data Type [data-type] Does not Exist In JHP Lazy");
        console.error(jQueryElement);
    }

}

/* Loading On Click Watcher */

onClickLazyLoad.each(function() {
    let $this = $(this);
    let trigger = $this.attr('data-trigger');
    $(trigger).click(function() {
        let outOfEvent = $this.attr('data-outOfEvent');
        console.log("On Click Element Watcher Report: -- Trigger element Clicked");
        console.log("On Click Element Watcher Report: -- Trigger:");
        console.log($(trigger));
        if (outOfEvent == undefined) {
            console.log("On Click Element Watcher Report: --  Sending Element To loading cop");
            console.log($this);
            loadingCop($this);
            $this.attr('data-outOfEvent', '1');
        } else {
            console.warn('The Element of this Trigger Was Already Loaded');
        }
    });
});

/* / Loading On Click Watcher */

/* Events */

let NumberOfChecks = 0;
let AmountOfChecks = 3;
const addEvents = function(Message) {
    NumberOfChecks++;
    if (verbose) {
        console.log(`
            Add Events Function Report:
            ----------------------------
            Amount of Checks Done: ${NumberOfChecks}
            Amount of Checks Left: ${AmountOfChecks - NumberOfChecks}
            Total Amount of Checks : ${AmountOfChecks}
            Check Debug Message : ${Message}
        `);
    }
    if (NumberOfChecks === AmountOfChecks) {
        win.scroll(function() {

            /* On Viewport Watcher */

            onViewportLazyLoad.each(function() {
                let $this = $(this);
                if (isOnViewport($this)) {
                    let outOfEvent = $this.attr('data-outOfEvent');
                    if (outOfEvent == undefined) {
                        if (verbose) {
                            console.log('On Viewport Element Watcher On Viewport Lazy Load Element Detected');
                            console.log('On Viewport Element Watcher Sending element To The Loading Cop');
                            console.log($this);
                            console.log("On Viewport Element Watcher Taking Element out of event");
                            console.log(` On Viewport Element Watcher Element.attr(data-outOfEvent) = ${$this.attr('data-outOfEvent')}`);
                        }
                        $this.attr('data-outOfEvent', '1');
                        loadingCop($this);
                    }
                }
            });

            /* / On Viewport Watcher */

        });



        doc.ready(function() {

            /* On Viewport Initial Trigger */

            onViewportLazyLoad.each(function() {
                let $this = $(this);
                if (isOnViewport($this)) {
                    let outOfEvent = $this.attr('data-outOfEvent');
                    if (outOfEvent == undefined) {
                        if (verbose) {
                            console.log('On Viewport Element Watcher Report: --- On Viewport Lazy Load Element Detected');
                            console.log('On Viewport Element Watcher Report: --- Sending element To The Loading Cop');
                            console.log($this);
                            console.log("On Viewport Element Watcher Report: --- Taking Element out of event");
                            console.log(` On Viewport Element Watcher Report: --- Element.loaded = ${$this.attr('data-outOfEvent')}`);
                        }
                        $this.attr('data-outOfEvent', '1');
                        loadingCop($this);
                    }
                }
            });

            /* / On Viewport Initial Trigger */
            
        });
    }
}

/* / Events */

/* / Functions */